@media (orientation: landscape) {
  #casinoFrame {
    height: 100vh !important;
    width: 100vw !important;

    z-index: 1000000 !important;

    top: 0 !important;
  }
}
