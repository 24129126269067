.bet-slip-container {
    padding-left: 0px!important;
    padding-right: 0px!important;
    padding-top: 56px;
    // height: calc(100vh - 56px);
    height: 100vh;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
  }

.bet-slip-top {
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    background-color: #000;
    position: fixed;
    top: 56px;
    right: 0px;
    left: 0px;
    z-index: 100;
}

.bet-slip-row {
    // margin-top: 31px;
    margin-bottom: 1rem;
    border-left: transparent;
    border-right: transparent;
    border-style: solid;
    padding: 20px 10px 5px;
    border-top: 1px solid #E5E5E5;
    border-bottom: 2px solid #E5E5E5;
    -webkit-box-shadow: 2px 3px 6px 0px rgb(233 232 232 / 90%);
    box-shadow: 2px 3px 6px 0px rgb(233 232 232 / 90%);
}
.bet-slip-center {
    padding: 1rem 0;
    &.calculated-height {
        position: fixed;
        height: var(--betSlipCenterHeight);
        top: var(--betSlipCenterTopOffset);
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
    }
}

.bet-slip-bottom {

    position: fixed;
    bottom: 0px;
    border: 1px;
    background: #000;
    width: 100%;
    margin-right: 0px !important;
    margin-left: 0px !important;
    /* between bootstrap footer and offcanvas z-index */
    z-index: 1035;
    padding-bottom: calc(6px + env(safe-area-inset-bottom, 0px));

    .stakeExpanded-height {
        min-height: 200px !important;
    }
}  

.bet-slip {
    background: #000; 
    margin-top: 20px;       
    border: 1px;
    width: 100%;
    margin-right: 0px !important;
    margin-left: 0px !important;      
    z-index: 1035;
    padding-bottom: calc(6px + env(safe-area-inset-bottom, 0px));     
    border-radius: 0.25rem;

    .stakeExpanded-height {
        min-height: 200px !important;
    }
}