@import '~swiper/css';
@import '~swiper/css/navigation';
@import '~react-toastify/dist/ReactToastify.css';

// begin:: sport league detail
.scrollable-y {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.tip-types-container {
  position: relative;
  height: 55px;
  // &.live-matches {
  //     .tip-types {
  //         top: 0px
  //     }
  // }
}

.tip-types {
  background-color: #fff;
  position: absolute;
  top: 0px;
  left: 0;

  &.fixed {
    position: fixed;
    top: 56px !important;
    // width: calc(100% - 15px);
    width: 100%;
  }

  // position: fixed;

  // &.scrolling {
  //     transform: translateY(0);

  //     &.offset {
  //         transform: translateY(-106px);
  //     }

  //     &.up {
  //         transform: translateY(-200px);
  //     }
  // }
}

//

// begin:: Betting History
.betting-history-carousel {
  padding: 0 0 1.5rem;

  .carousel-inner {
    overflow: inherit;
  }

  .carousel-indicators {
    margin-bottom: 0;

    button {
      height: 12px;
      width: 12px;
      min-width: 12px;
      border-radius: 50%;

      &[aria-label='won'] {
        background-color: var(--bs-success) !important;
      }

      &[aria-label='lost'] {
        background-color: var(--bs-danger) !important;
      }

      &[aria-label='undecided'] {
        background-color: var(--bs-warning) !important;
      }

      &[aria-label='cancelled'] {
        background-color: var(--bs-gray-600) !important;
      }

      &[aria-label='cashout'] {
        background-color: var(--bs-blue) !important;
      }
    }
  }
}

// end Betting History

// begin:: Header Language Dropdown
.language-dropdown {
  .dropdown-toggle {
    img {
      width: 24px;
      height: auto;
    }
  }

  .dropdown-item {
    img {
      width: 24px;
      height: auto;
    }
  }
}

// end:: Header Language Dropdown

// DESKTOP

.filter-sidebar {
  // width: 24%;
  // max-width: 250px;
  min-width: 250px;
  text-align: left;

  .list-group {
    :hover {
      background-color: #00cf06;
      color: white;
    }

    .list-group-item {
      color: white;
      border: solid;
      border-color: #151d1d;

      &.active {
        background-color: #00cf06;
        color: white;
        border-color: #1e2626;

        .badge {
          color: white;
        }
      }
    }
  }

  .image-passive {
    filter: brightness(0) invert(1);
  }

  .accordion {
    border: solid;
    border-color: #151d1d;
  }

  .accordion-item {
    background-color: #293333;
    border: 1px solid #293333;
    border-color: #151d1d;
  }

  .accordion-header {
    background-color: #293333;
    color: white;
    padding: 0.5rem 1rem;
    font-size: inherit;
    border: none;
    text-align: left;
    width: 100%;
    border-color: #151d1d;
  }

  .accordion-header:not(.collapsed) {
    color: white;

    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  }

  .accordion-button {
    padding: 0.5rem 1rem;
    font-size: inherit;
    border: solid;
    border-color: #151d1d;
  }

  .accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.125);
  }

  .accordion-button:hover {
    background-color: #00cf06;
  }

  .accordion-button:not(.collapsed) {
    color: white;
    background-color: #00cf06;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
    border: solid;
    border-color: #151d1d;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: none;
  }

  .accordion-button::after {
    background-image: none;
  }
}

.betslip-sidebar {
  position: sticky;
  top: 100px;
  background-color: #1e2626;

  // width: 21%;
  // min-width: 160px;
  .card-header {
    border-radius: 0;
  }

  .card-body {
    padding: 0rem 0rem;
    overflow: none;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background: #333;
  }
}

.desktop-main {
  background-color: #1e2626;
}

.desktop-league {
  // width: 55%;
  .desktop-league-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;

    .bets-header-wrapper {
      display: flex;
      // card padding-x + link width + wrapper gap
      padding-right: calc(0.75rem + 40px + 0.5rem);
      gap: 0.5rem;

      .bet-group-header {
        text-align: center;
        width: 150px;
      }
    }
  }

  // .desktop-league-matches {}
}

.desktop-match-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background-color: #293333;

  .time {
    width: 50px;
  }

  .teams-group {
    flex: 1;
    text-align: start;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    width: 160px;
    min-width: 160px;
    display: flex;
    flex-direction: column;
  }

  .bets-wrapper {
    // flex-grow: 1;
    display: flex;
    gap: 0.5rem;

    .bet-group {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      width: 150px;

      .bet-secondary {
        margin-right: 0.5rem;
      }
    }
  }

  .match-details-link-wrapper {
    width: 40px;
  }
}

.mt-1-35 {
  margin-top: 1.35rem;
}
