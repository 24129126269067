.border-tertiary {
    border: 1px solid #cacaca;
    box-shadow: 1px 2px 7px 0 #00000040;
}

.bg-dark-light {
    background-color: #333;
}

.bg-dark-body {
    background-color: #1e2626;
}

.bg-header {
    background-color: #151d1d;
}

.bg-gray-400 {
    background-color: #F6F6F6;
}

.bg-gray-600 {
    background-color: #dcdcdc;
}

.border-gray-300 {
    border: 1px solid #dad4d4;
    box-shadow: 1px 2px 7px 0 #00000040;
}

.flex-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.bg-dark-green {
    background-color: green;
}

.min-w- {
    &40 {
        min-width: 40px;
    }
}

.text-color-green {
    color: #00cf06;
}

.border-left-green {
    border-bottom: solid;
    border-color:#00cf06
}

.bg-list-dark{
    background-color: #293333;
}

.text-desktop-time {
    color: #9a9e9e;
}

.text-desktop-time-active {
    color: #00ce03;
}

.bg-button-dark {
    background-color: #3B4242;
}

.text-yellow {
    color:#f6cf0a
}

.border-card {
    border:solid;
    border-color: #293333;
}

.text-dark-light {
    color:#c9cccc;
}

.bg-green { 
    background-color: #00cd00;
}

.bg-item-dark {
    background-color: #3c4040;
}

.text-light-gray {
    color: #8f8f8f;
}

.text-color-blue {
    color:#0279f7
}

.bg-dark-300 {
    background-color: #394141;
}