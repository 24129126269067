@font-face {
  font-family: 'SFProText';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/SFProTextRegular.otf') format('opentype');
}

@font-face {
  font-family: 'SFProText';
  font-style: normal;
  font-weight: 500 700;
  src: url('../fonts/SFProTextSemibold.otf') format('opentype');
}

body {
  height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
  height: 100vh;
}

body {
  overflow: hidden;
  overflow-y: auto;
}

:root {
  --header-height: 56px;
  /* --footer-height: 68px; */
  --footer-height: calc(68px + env(safe-area-inset-bottom, 0px));
}

@include media-breakpoint-up(md) {
  :root {
    --header-height: 90px;
  }
}

.App {
  width: 100%;
  height: 100%;
  min-height: 100vh;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: column;
  }
}

.App>header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  height: var(--header-height);
}

.App>main {
  /* --contentHeight: 100vh; */
  padding-top: var(--header-height);
  // padding-bottom: var(--footer-height);
  min-height: 100%;
  height: 100%;
  /* height: calc(100vh - var(--footer-height) - var(--header-height)); */
  /* height: calc(100vh - env(safe-area-inset-bottom, 0px)); */
  overflow-x: hidden;
  flex-grow: 1;

  @include media-breakpoint-down(lg) {
    min-height: 100vh;
    padding-bottom: var(--footer-height);
  }

  @include media-breakpoint-up(lg) {
    overflow: unset;
  }
}

.App>.mobile-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1030;
  padding-bottom: env(safe-area-inset-bottom, 0px);
  height: var(--footer-height);
  background-color: #f5f5f5;
}

/* .App > main {
      padding-bottom: env(safe-area-inset-bottom, 0px);
    } */