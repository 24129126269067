.nav-warning {
  .active {
    background-color: var(--bs-warning) !important;
    // color: #fff !important;
  }
}

.no-arrow {
  &::after {
    display: none;
  }
}

// .fw-medium {
//     font-weight: 500;
// }

.btn-success {
  color: #fff;
}