$enable-negative-margins: true;

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 2,
    6: $spacer * 3,
    7: $spacer * 4,
    75: $spacer * 0.75
);
$primary: #af021f;
$danger: #af021f;
$info: #007bff;
$warning: #ffcc00;
$success: #3aa843;
$dark: #000000;
$secondary: #6c757d;
$light: #f8f9fa;

$theme-colors: (
    primary: $primary,
    secondary: $secondary,
    danger: $danger,
    info: $info,
    warning: $warning,
    success: $success,
    dark: $dark,
    light: $light,
);

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
    7: $font-size-base * 0.875,
    8: $font-size-base * 0.75,
    9: $font-size-base * 0.625
);

$body-color: #334547;
$body-bg: #fefefe;
$font-family-base: "SFProText";