html {
  height: -webkit-fill-available;
  height: 100vh;
}

:root {
  --header-height: 56px;
  /* --footer-height: 68px; */
  --footer-height: calc(68px + env(safe-area-inset-bottom, 0px));
}

.App > header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  height: var(--header-height);
}

.App > main {
  /* --contentHeight: 100vh; */
  padding-top: var(--header-height);
  /* padding-bottom: var(--footer-height); */
  min-height: 100%;
  height: 100%;
  /* height: calc(100vh - var(--footer-height) - var(--header-height)); */
  /* height: calc(100vh - env(safe-area-inset-bottom, 0px)); */
  /* height: 100vh; */
  overflow-x: hidden;
}

/* .App > main {
  padding-bottom: env(safe-area-inset-bottom, 0px);
} */

a {
  text-decoration: none !important;
  color: inherit;
}
.registration_banner_text {
  position: absolute;
  top: 55%;
  left: 65%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  white-space: nowrap;
  float: right;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.flag img {
  height: 23px;
}

@keyframes modalSlide {
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.custom-modal-open {
  display: block;
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 20%;
  width: 100%;
  height: 90%;
  background-color: #fff;
  z-index: 666666;
  animation: modalSlide 0.3s ease-out forwards;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.hr-modal {
  margin-top: 50px;
  opacity: 100% !important;
  margin-bottom: 0px !important;
}

.subHeader-modal {
  background: #000;
  height: 60px;
  margin-top: -8px;
  padding-top: 10px;
}

.svg-size-20 {
  height: 20px;
  width: 20px;
}

a.carousel-control-prev {
  display: none;
}

a.carousel-control-next {
  display: none;
}

.match-tracker ol.carousel-indicators {
  top: calc(100% + 15px);
}

.match-tracker ol li {
  background-color: white;
  height: 5px;
  width: 35px;
  display: flex;
  margin-left: 15px;
}

.match-tracker ol li.active {
  background-color: green;
  height: 5px;
  width: 35px;
}

.carousel-item {
  height: 70%;
  background-color: #fff;
}

.bet-slip-segment-button {
  padding: 7px 20px 7px;
  cursor: pointer;
  margin: 10px -6px 2px;
  border-radius: 7px;
  opacity: 0.2;
  background-color: #ffffff;
  color: #333333 !important;
}

*::selection {
  -webkit-appearance: none;
  outline: none;
}

.numpad-container {
  z-index: 999999;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #635d5d;
}

.numpad-button {
  height: 47px;
  padding-top: 8px !important;
  border: 1px solid #000;
  color: white !important;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  background-color: #50585a;
}

a.preset {
  padding: 13px;
  margin: 5px;
  border-radius: 5px;
  color: #ffff !important;
  cursor: pointer;
}

.banker-bg {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  align-items: center;
}

.combination-scrollView {
  max-height: 150px;
  float: left;
  overflow-y: scroll;
  padding: 8px 0 8px;
  margin-top: 5px;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

select#live_match_sorting {
  border: 0px;
  outline: 0px;
  color: gr;
}

.place-bet-modal {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: fixed;
  top: 40%;
  left: 0;
  right: 0;
  z-index: 9999999;
  background-color: #fff;
  margin: 60px 20px;
  transform: translate(0, -50%);
}

.place-bet-modal-inner {
  margin: 20px 0 20px 0;
}

.green-underline {
  border-bottom: 3px solid rgb(58, 181, 58);
}

.tip-types {
  width: 100%;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  min-height: 40px;
  transition: all 0.5s;
  -webkit-transition: transform 0.5s;
  -webkit-transition: -webkit-transform 0.5s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  gap: 0 0.25rem;
}

/* .active { 
  color:#3b69c8 !important;
} */
svg {
  fill: currentcolor;
}

.footer .active img {
  filter: invert(8%) sepia(81%) saturate(5892%) hue-rotate(340deg) brightness(100%) contrast(113%);
}

.footer-slot .active {
  color: #af021f !important;
}

.footer-slot .active img {
  filter: invert(8%) sepia(81%) saturate(5892%) hue-rotate(340deg) brightness(100%) contrast(113%);
}

.circle-overflow {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}

.circle {
  border-radius: 50%;
  background-color: #ffcc00;
  font-size: 22px;
  font-weight: 700;
  display: grid;
  place-content: center;
  height: 58px;
  width: 58px;
}

.transaction-deposit {
  color: chartreuse;
}

.odd-decreased {
  position: relative;
}

.odd-decreased:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  border-width: 0 0 10px 10px;
  border-style: solid;
  border-color: #ff3b30 transparent;
}

.odd-increased {
  position: relative;
}

.odd-increased:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-width: 10px 0 0 10px;
  border-style: solid;
  border-color: #2db343 transparent;
}

.odd-decreased-slip {
  position: relative;
}

.odd-decreased-slip:before {
  content: '';
  position: absolute;
  bottom: -5px;
  right: -5px;
  border-width: 0 0 10px 10px;
  border-style: solid;
  border-color: #ff3b30 transparent;
}

.odd-increased-slip {
  position: relative;
}

.odd-increased-slip:before {
  content: '';
  position: absolute;
  top: -5px;
  right: -5px;
  border-width: 10px 0 0 10px;
  border-style: solid;
  border-color: #2db343 transparent;
}

.serving-ball {
  max-width: 10px;
}

.slot-size {
  width: 100%;
  height: calc(100% - var(--header-height));
  top: var(--header-height);
  bottom: 0;
  left: 0;
}

/*Seach Input clear icon cursor change*/
input[type='search']::-webkit-search-decoration:hover,
input[type='search']::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

.icon-color {
  filter: invert(1);
}

.icon-white {
  filter: brightness(0) invert(1);
}

.active-icon-color {
  filter: invert(8%) sepia(81%) saturate(5892%) hue-rotate(340deg) brightness(100%) contrast(113%);
}

.svg-color {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.light-black-svg {
  -webkit-filter: invert(0.2); /* Safari and Chrome */
  filter: invert(0.2);
}

.header-slot .active {
  color: #00cf06 !important;
  text-decoration: underline;
}

.wil-be-live {
  background-color: green;
}

.header-bg-color {
  background-color: #af021f !important;
}

.match-date-bg-color {
  background-color: #af021f !important;
}

.betslip-title-badge {
  top: -1px;
  font-size: 15px;
  position: absolute;
  float: none;
  margin-left: 120px;
}

.text-color {
  color: #af021f !important;
}
