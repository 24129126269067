.sport-button {
    background-color: #293333 !important;
    display: flex !important;
    justify-content: space-between;
    border-radius: 4px !important;
    padding: 0.75rem 1rem !important;
    color: #fff !important;
    border: 0 !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    width: 100%;

    &.active {
        color: #00cf06 !important;
    }

    .sport-plus {
        display: none;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-20%, -50%);
    }

    &:hover {
        background-color: #00cf06 !important;
        color: #fff !important;

        .sport-count {
            display: none;
        }

        .sport-plus {
            display: inline-block;
        }
    }
}

.category-button {
    background-color: #3c4040 !important;
    display: flex !important;
    border-radius: 4px !important;
    padding: 0.75rem 1rem !important;
    color: #fff !important;
    border: 0 !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    width: 100%;
    gap: 0.5rem;

    &.active {
        color: #00cf06 !important;
    }

    .category-text {
        text-align: start;
    }

    .category-plus {
        display: none;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-20%, -50%);
    }

    .category-count {
        margin-left: auto;
    }

    &:hover {
        .category-count {
            display: none;
        }

        .category-plus {
            display: inline-block;
        }
    }
}

.tournament-button {
    background-color: #3c4040 !important;
    display: flex !important;
    border-radius: 0 !important;
    padding: 0.75rem 1rem 0.75rem 2rem !important;
    color: #fff !important;
    border: 0 !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    width: 100%;
    gap: 0.5rem;

    &.active {
        color: #00cf06 !important;
    }

    .tournament-text {
        text-align: start;
    }

    .tournament-plus {
        display: none;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-20%, -50%);
    }

    .tournament-count {
        margin-left: auto;
    }

    &:hover {
        .tournament-count {
            display: none;
        }

        .tournament-plus {
            display: inline-block;
        }
    }
}